import React, { useState } from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { InboundLink, OutboundLink } from "../link"
// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import ImageAndText from "./imageAndText"

SwiperCore.use([Mousewheel, Pagination, A11y])

const ActorCollection = ({ title, actors }) => {
  return (
    <Box sx={{ position: "relative", backgroundColor: "primary", py: [5, 6] }}>
      <Container
        variant="custom"
        sx={{ py:[0,0]}}
      >
        <Heading
          variant="h2"
          as="h2"
          sx={{
            mt: [0, 0],
            mb: [3, 4],
            color: "dark",
            textTransform: "lowercase",
          }}
        >
          {title}
        </Heading>
      </Container>
      <Container
        sx={{ py: [0, 0], }}
      >
        {actors.map((actor, index) => (
          <Box
            sx={{
              borderTop: index === 0 && "1px solid", borderColor: "dark",
              position: "relative",
              backgroundColor: title !== "Header" ? "none" : "primary",
            }}
          >
            <Box
              sx={{
                position: ["relative"],
                width: "100%",
                height: "100%",
                py: [5, 5],
              }}
            >
                <Grid gap={[4,8]} columns={[1, ".5fr .5fr"]}>
                  <Box
                    sx={{
                      py: [0, 8],
                      gridColumn: [index % 2 === 0 ? 1 : 1,index % 2 === 0 ? 1 : 2],
                      gridRowStart: [2,1],
                      gridRowEnd: [2,1],
                    }}
                  >
                    <Heading
                      variant="h3"
                      as="h3"
                      sx={{
                        lineHeight: "40px",
                        color: "dark",
                      }}
                    >
                      {actor.title}
                    </Heading>
                    <Box
                      sx={{
                        p: {
                          fontSize: [2, 3],
                          fontWeight: "500",
                          color: "dark",
                        },
                      }}
                    >
                      <RichContentStructuredText text={actor.body} />
                    </Box>

                    <Box>
                      <Flex
                        sx={{
                          width: "100%",
                          justifyContent: "start",
                        }}
                      >
                        <OutboundLink
                          target="_blank"
                          href={actor.link.url}
                          sx={{
                            display: "inline-block",
                            mt: [4],
                            border: "1px solid",
                            borderColor: "dark",
                            py: [2],
                            px: [3],
                            position: "relative",
                            color: "dark",
                            textDecoration: "none",
                            borderRadius: "lg",
                            textTransform: "lowercase",
                            fontSize: [3, 3],
                            transition: "all ease .3s",
                            transform: ["rotate(-10deg)","rotate(-10deg)","rotate(-0deg)"],
                            "&:hover": {
                              transform: "rotate(-10deg)",
                            },
                          }}
                        >
                          {actor.link.anchor}
                        </OutboundLink>
                      </Flex>
                    </Box>
                  </Box>
                  {actor.image && (
                    <Box
                      sx={{
                        gridColumn: [index % 2 === 0 ? 1 : 1,index % 2 === 0 ? 2 : 1],
                        gridRow:[1,"auto"],
                        position: "relative",
                        ".gatsby-image-wrapper": {
                          width: "100%",
                          position: ["relative","absolute"],
                          height: "100%",
                          img: {
                            height: "100%",
                          },
                        },
                      }}
                    >
                      <GatsbyImage image={actor.image.gatsbyImageData} alt="" />
                    </Box>
                  )}
                </Grid>

            </Box>
          </Box>
        ))}
      </Container>
    </Box>
  )
}
export default ActorCollection
