import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"

const EventCollection = ({ title, eventi }) => {
  return (
    <Box sx={{ position: "relative", py: [5, 6] }}>
      <Container variant="custom"   sx={{ py:[0,0]}}>
        <Heading
          variant="h2"
          as="h2"
          sx={{ mt:[0,0],mb:[3,4], color: "dark", textTransform: "lowercase" }}
        >
          {title}
        </Heading>
      </Container>
      <Container  sx={{py:[0,0]}}>
        <Grid  >
          {eventi.map((evento) => (
            <Grid 
            columns={[1,".22fr .78fr"]}
            sx={{ borderTop:"1px solid" , borderColor:"primary", py:[3,4]}}
            >
              <Box >
                <Heading as="h3" variant="h3" sx={{ my:[0,0], textDecoration:"underline", }}>
                  {evento.title}
                </Heading>
              </Box>
              <Box sx={{ h3:{ fontWeight:"500", color:"dark", lineHeight:["40px","70px"]}}}>
                <RichContentStructuredText text={evento.body} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default EventCollection
