import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import Marquee from "react-fast-marquee"

import * as Scroll from "react-scroll"

const LinkScroll = Scroll.Link

const TestUsSection = ({ title, description, linkText, list, image }) => {
  return (
    <Box sx={{ position: "relative", overflow: "hidden", my: [6] }}>
      <Box sx={{ position: "absolute", top: [6], zIndex:[1,1], transform: "rotate(-4deg)" }}>
        <Marquee gradient={false} speed={80}>
          <Box
            as="p"
            sx={{
              fontSize: [4, 7],
              letterSpacing: "-0.49",
              color: "primary",
              fontWeight: "600",
            }}
          >
            {title}
          </Box>
        </Marquee>
      </Box>
      <Container sx={{position:"relative", zIndex:[0,0]}}>
        <Grid columns={[1, ".35fr .65fr"]} gap={[0,6]}>
          <Box
            sx={{
              position: "relative",
              ".gatsby-image-wrapper": {
                width: "100%",
                position: ["relative","absolute"],
                height: "100%",
                img: {
                  height: "100%",
                },
              },
            }}
          >
            <GatsbyImage image={image.gatsbyImageData} alt="" />
          </Box>
          <Box sx={{ pt: [4, 9] }}>
            <Box>
              <Text
                as="div"
                variant="p"
                sx={{
                  p: { fontSize: [3, 4], my: [2, 3] },
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
            <Grid columns={[1, 1, 3]} sx={{ py:[4]}}>
              {list.map(item => (
                <Flex
                  sx={{
                    alignItems: "start",
                    color: "primary",
                    fontWeight: "600",
                  }}
                >
                  <Box as="span" sx={{ mr: [4] }}>
                    ⟶
                  </Box>
                  <Box>
                    <Text as="p" sx={{ fontSize: [1] }}>
                      {item.title}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </Grid>
            <Box
              sx={{
                mb: [4],
                mt: [6,8],
                textAlign: "center",
                a: {
                  display: "inline-block",
                  border: "1px solid",
                  borderColor: "primary",
                  py: [2],
                  px: [6],
                  position: "relative",
                  color: "primary",
                  textDecoration: "none",
                  borderRadius: "48px",
                  textTransform: "lowercase",
                  fontSize: [4, 7],
                  transition: "all ease .3s",
                  transform: ["rotate(-10deg)","rotate(-10deg)","rotate(-0deg)"],
                  "&:hover": {
                    transform: "rotate(-10deg)",
                  },
                },
              }}
            >
              <LinkScroll
                to="contactForm"
                href="#"
                spy={false}
                smooth={true}
                duration={500}
              >
                {linkText}
              </LinkScroll>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default TestUsSection
