import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import * as Scroll from "react-scroll"

const LinkScroll = Scroll.Link

const ImageAndText = ({ title, text, image, video, linkText }) => {
  return (
    <Box
      sx={{
        py: [4, 4],
        position: "relative",
        backgroundColor: title !== "Header" ? "none" : "primary",
      }}
    >
      {video && (
        <>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              zIndex: 1,
              backgroundColor: "rgba(0,0,0,.5)",
            }}
          ></Box>

          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 0,
              top: 0,
            }}
          >
            <video
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              title={"background-video"}
              id=""
              class=""
              autoplay="autoplay"
              muted="muted"
              loop="loop"
              playsinline="playsinline"
            >
              <source src={video.url} type="video/mp4" />
            </video>
          </Box>
        </>
      )}
      <Box
        sx={{
          position: ["relative"],
          width: "100%",
          height: "100%",
          zIndex: 3,
        }}
      >
        {title !== "Header" && (
          <Container variant="custom"  sx={{ py:[0,0]}}>
            <Heading
              variant="h2"
              sx={{
                fontWeight:"400",
                color: video ? "light" : "primary",
              }}
            >
              {title !== "Header" && title}
            </Heading>
          </Container>
        )}
        <Container sx={{ pt:[0,video ? 0: 6]}}>
          <Grid
            gap={[4, 8]}
            columns={[1, 1, 1, ".6fr .4fr"]}
            sx={{
              borderTop: title !== "Header" && "1px solid",
              borderColor: video ? "light" : "dark",
            }}
          >
            <Box sx={{ height: "100%", width: "100%", position: "relative" }}>
              <Text
                as="div"
                sx={{
                  p: {
                    width: video ? ["100%","80%","80%","55%"] : ["100%","100%","100%"],
                    fontSize: video ? [3, 4] : [3, 6],
                    letterSpacing: "-0.33px",
                    lineHeight: ["1.5", "40px"],
                    fontWeight: "500",
                    color: video ? "light" : "dark",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: text }}
              />

              <Box>
                <Box
                  sx={{
                    mb: [2],
                    a: {
                      display: "inline-block",
                      mt: [4],
                      border: "1px solid",
                      borderColor: video ? "light" : "dark",
                      py: [2],
                      px: [3],
                      position: "relative",
                      color: video ? "light" : "dark",
                      textDecoration: "none",
                      borderRadius: "lg",
                      textTransform: "lowercase",
                      fontSize: [3, 4],
                      transition: "all ease .3s",
                      transform: [
                        "rotate(-10deg)",
                        "rotate(-10deg)",
                        "rotate(-0deg)",
                      ],
                      "&:hover": {
                        transform: "rotate(-10deg)",
                      },
                    },
                  }}
                >
                  <LinkScroll
                    to="contactForm"
                    href="#"
                    spy={false}
                    smooth={true}
                    duration={500}
                  >
                    {linkText}
                  </LinkScroll>
                </Box>
              </Box>
            </Box>
            {image && (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  ".gatsby-image-wrapper": {
                    width: "100%",
                    position: ["relative","relative","relative", "absolute"],
                    height: "100%",
                    img: {
                      height: "auto",
                    },
                  },
                }}
              >
                <GatsbyImage image={image.gatsbyImageData} alt="" />
              </Box>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default ImageAndText
