import React, { useState } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Flex,
  Label,
  Link,
} from "@theme-ui/components"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { Textarea, Checkbox } from "theme-ui"
import axios from "axios"
import { InboundLink, OutboundLink } from "../link"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import RichContentStructuredText from "../richContentStructuredText"
import { i18nContext } from "../../context/i18nContext"

const ContactForm = ({ title, description, contactPage, privacyPolicyDescription, newsletterDescription }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dark = getColor(themeUiTheme, "dark")
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    email: "",
    nomeCognome: "",
    ragioneSociale: "",
    telefono: "",
    messaggio: "",
    accordoPrivacyCookies: false,
    accordoNewsLetter: false,
    urlPagina: isBrowser && window.location.href,
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendMail = async event => {
    event.preventDefault()

    const result = await executeRecaptcha("dynamicAction")

    setLoading(true)
    const data = formData

    if (result)
      axios
        .post("/.netlify/functions/sendContactMail", data)
        .then(function (response) {
          setSuccess(true)
          setLoading(false)

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {
            window.dataLayer = window.dataLayer || []

            window.dataLayer.push({
              event: "formSubmission",
              formType: "Contact",
            })
          }

        })
        .catch(function (error) {
          setLoading(false)
        })
    else setLoading(false)
  }

  return (
    <Box
      sx={{
        py: [3, 3, 3, 3],
        backgroundColor: contactPage ? "dark" : "primary",
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Container variant="custom" sx={{ py: [0, 0] }}>
              <Heading
                variant="h2"
                as="h2"
                sx={{
                  mt: [0, 0],
                  mb: [3, 4],
                  color: "dark",
                  textTransform: "lowercase",
                }}
              >
                {title}
              </Heading>
            </Container>
            <Container sx={{ py: [0, 0] }}>
              <Box>
                <Grid
                  columns={["1fr", "1fr", "1fr", ".4fr .6fr"]}
                  gap={[1, 1, 1, 6]}
                  sx={{ borderTop: "1px solid", borderColor: "dark" }}
                >
                  <Box>
                    <Text
                      as="div"
                      variant="sectionTitle"
                      sx={{
                        my: [0, 4],
                        p: {
                          a: {
                            color: "dark",
                          },
                        },

                        fontSize: [4, 5],
                        fontWeight: 300,
                        letterSpacing: "-.5px",
                        lineHeight: "1.12",
                        em: {
                          fontStyle: "normal",
                          color: "dark",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </Box>
                  {!success ? (
                    <Box
                      as="form"
                      id="contactForm"
                      onSubmit={sendMail}
                      sx={{ my: [4] }}
                    >
                      <Box>
                        <Grid
                          sx={{
                            flexWrap: "wrap",
                            flexDirection: "column",
                          }}
                          gap={[0, 0, 0, 0]}
                        >
                          <Flex sx={{ flexWrap: "wrap" }}>
                            <Box
                              sx={{
                                pr: [0, 1, 1, 1],
                                pb: [3, 3, 3, 3],
                                width: ["100%", "50%"],
                              }}
                            >
                              <Input
                                onChange={e =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    nomeCognome: e.target.value,
                                  }))
                                }
                                name="nome cognome"
                                placeholder={t.formInputTexts.fullName}
                                variant={"inputs.primary"}
                                required
                              />
                            </Box>
                            <Box
                              sx={{
                                pl: [0, 1, 1, 1],
                                pb: [3, 3, 3, 3],
                                width: ["100%", "50%"],
                              }}
                            >
                              <Input
                                onChange={e =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    ragioneSociale: e.target.value,
                                  }))
                                }
                                name="ragione sociale"
                                placeholder={t.formInputTexts.company}
                                variant={"inputs.primary"}
                                required
                              />
                            </Box>
                          </Flex>
                          <Flex sx={{ flexWrap: "wrap" }}>
                            <Box
                              sx={{
                                pr: [0, 1, 1, 1],
                                pb: [3, 3, 3, 3],
                                width: ["100%", "50%"],
                              }}
                            >
                              <Input
                                onChange={e =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    email: e.target.value,
                                  }))
                                }
                                name="email"
                                type="email"
                                placeholder="Email*"
                                variant={"inputs.primary"}
                                required
                              />
                            </Box>
                            <Box
                              sx={{
                                pl: [0, 1, 1, 1],
                                pb: [3, 3, 3, 3],
                                width: ["100%", "50%"],
                              }}
                            >
                              <Input
                                onChange={e =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    telefono: e.target.value,
                                  }))
                                }
                                name="telefono"
                                type="text"
                                placeholder={t.formInputTexts.phone}
                                variant={"inputs.primary"}
                                required
                              />
                            </Box>
                          </Flex>

                          <Textarea
                            sx={{
                              fontFamily: "body",
                              fontSize: [2],
                            }}
                            onChange={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                messaggio: e.target.value,
                              }))
                            }
                            name="messaggio"
                            placeholder={t.formInputTexts.message}
                            variant={"inputs.primary"}
                            type="textarea"
                          />
                          <LabeledCheckbox
                            checkedCheckbox={e => {
                              setFormData(prevState => ({
                                ...prevState,
                                accordoPrivacyCookies: e,
                              }))
                            }}
                            required={true}
                            contactPage={contactPage}
                          >
                            <Box>
                              <Text
                                variant="p"
                                sx={{
                                  fontSize: [0, 0],
                                  letterSpacing: "0px",
                                  color: "dark",
                                  p: { my: 0 },
                                  a: {
                                    color: "dark"
                                  }
                                }}
                                dangerouslySetInnerHTML={{ __html: privacyPolicyDescription }}
                              />
                            </Box>
                          </LabeledCheckbox>
                          <LabeledCheckbox
                            checkedCheckbox={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                accordoNewsLetter: e,
                              }))
                            }
                            contactPage={contactPage}
                          >
                            <Box>
                              <Text
                                variant="p"
                                sx={{
                                  fontSize: [0, 0],
                                  letterSpacing: "0px",
                                  lineHeight: "1.4",
                                }}
                                dangerouslySetInnerHTML={{ __html: newsletterDescription }}
                              />
                            </Box>
                          </LabeledCheckbox>
                          <Box
                            sx={{
                              mt: [3, 4],
                            }}
                          >
                            <Button
                              type="submit"
                              title={
                                !formData.accordoPrivacyCookies
                                  ? "Acconsenti al trattamenti dei dati personali"
                                  : ""
                              }
                              target="_blank"
                              sx={{
                                cursor: "pointer",
                                display: "inline-block",
                                mt: [4],
                                border: "1px solid",
                                borderColor: "dark",
                                py: [2],
                                px: [3],
                                position: "relative",
                                color: "dark",
                                textDecoration: "none",
                                borderRadius: "lg",
                                textTransform: "lowercase",
                                fontSize: [3, 3],
                                transition: "all ease .3s",
                                transform: [
                                  "rotate(-10deg)",
                                  "rotate(-10deg)",
                                  "rotate(-0deg)",
                                ],
                                "&:hover": {
                                  transform: "rotate(-10deg)",
                                },
                              }}
                              variant={
                                contactPage
                                  ? "buttons.primaryFormEmpty"
                                  : "buttons.primaryForm"
                              }
                            >
                              <Flex
                                sx={{
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {loading ? "Caricamento..." : t.submit}
                              </Flex>
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ transform: "rotate(-4deg)" }}>
                      <Heading
                        sx={{
                          my: [4],
                          color: contactPage ? "primary" : "dark",
                          fontSize: [6, 7],
                        }}
                        as="h4"
                      >
                        Richiesta inviata!
                      </Heading>
                    </Box>
                  )}
                </Grid>
              </Box>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  contactPage,
  checkedCheckbox,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: "normal",
        color: "dark",
        "input:checked~.css-kydphz": {
          color: "dark",
        },
        svg: {
          color: "dark",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        sx={{
          color: !contactPage && "dark",
          "input:checked~&": {
            color: !contactPage && "dark",
          },
        }}
        checked={checked}
        onChange={() => {
          checkedCheckbox(!checked)
          setChecked(!checked)
        }}
        {...props}
      />
      {children}
    </Label>
  )
}

export default ContactForm
