import React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Heading,
  Image,
  Flex,
  Grid,
  Box,
  Text,
} from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LatestArticles from "../components/latestArticles"
import { useFavicon } from "../hooks/useFavicon"
import logo from "../images/logo.svg"
import * as Scroll from "react-scroll"
import Unconventional from "../images/unconventional.inline.svg"
import { Autoplay } from "swiper"

import TextAndImage from "../components/blocks/imageAndText"
import EventCollection from "../components/blocks/eventCollection"
import SpaceCollection from "../components/blocks/spaceCollection"
import TestUsSection from "../components/blocks/testUsSection"
import ContactForm from "../components/blocks/contactFrom"
import ActorsCollection from "../components/blocks/actorCollection"
import { i18nContext } from "../context/i18nContext"
import LanguageSwitcher from "../components/languageSwitcher"

const LinkScroll = Scroll.Link

const Home = ({ data: { page, site, articles } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Box
        as="section"
        sx={{
          backgroundColor: "primary",
          height: "100%",
          width: "100%"
        }}
      >
        <i18nContext.Consumer>
          {t => (

            <>
              <Container
                variant="header"
                sx={{
                  borderBottom: "3px solid",
                  borderColor: "dark",
                  px: [3, 4],
                  py: [3, 4],
                }}
              >
                <Grid
                  columns={[2, 3, 3]}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    "> div": {
                      height: "100%",
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                    },
                  }}
                >
                  <Box
                    sx={{
                      img: { height: "100%" },
                    }}
                  >
                    <Image src={logo} />
                  </Box>
                  <Box sx={{
                    gridRow: [2, "auto", "auto"],
                    gridColumnStart: [1, "auto", "auto"],
                    gridColumnEnd: [3, "auto", "auto"],
                  }}>
                    <Text
                      sx={{
                        p: {
                          mb: 0,
                          fontWeight: "600",
                          letterSpacing: "-0.15px",
                          lineHeight: "22px",
                          fontSize: [3],
                        },
                      }}
                      as="div"
                      dangerouslySetInnerHTML={{ __html: page.kickerOne }}
                    />
                    <Grid sx={{ display:["block","none","none","none"],alignItems: "center", px: [4] }}>
                      <LanguageSwitcher />
                    </Grid>
                  </Box>

                  <Flex sx={{ justifyContent: "center", alignItems: "center" }}>
                    <Box
                      sx={{
                        mb: [3],
                        textTransform: "uppercase",
                        a: {
                          color: "light",
                          p: [3],
                          backgroundColor: "dark",
                          textTransform: "uppercase"
                        },
                      }}
                    >
                      <LinkScroll
                        to="contactForm"
                        href="#"
                        spy={false}
                        smooth={true}
                        duration={500}
                      >
                        {t.contactUs}
                      </LinkScroll>
                    </Box>
                    <Grid sx={{ display:["none","block","block","block"],alignItems: "center", px: [1,2,3,4] }}>
                      <LanguageSwitcher />
                    </Grid>
                  </Flex>
                </Grid>
              </Container>
              <Container sx={{ backgroundColor: "primary", py: [0, 0] }}>
                <Flex
                  sx={{
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    pt: [5, 8],
                    pb: [5, 6],
                  }}
                >
                  <Box
                    sx={{
                      width: "90%",
                      maxWidth: "768.59px",
                      height: "90%",
                      maxHeight: "550px",
                      position: "relative"
                    }}
                  >
                    <Unconventional />

                    <Heading
                      as="div"
                      sx={{
                        position: "absolute",
                        left: "95%",
                        top: "50%",
                        transform: "translate(-95%,-50%)",
                        "&::before": {
                          content: "''",
                          width: "115%",
                          height: "3px",
                          backgroundColor: "dark",
                          top: [-2],
                          position: "absolute",
                        },
                        h1: {
                          fontSize: ["10vw", "10vw", "10vw", "88px"],
                          lineHeight: ["1", "77px", "77px"],
                          my: [0, 0],
                          color: "dark",
                          fontWeight: "400",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: page.subtitle }}
                    />
                  </Box>
                </Flex>
                <Flex>
                  <Heading
                    as="div"
                    sx={{
                      m: "0 auto",
                      h2: {
                        fontSize: [4, 7, 9],
                        textAlign: "left",
                        letterSpacing: "-0.6px",
                        lineHeight: ["40px", "72px"],
                        fontWeight: "400",
                        color: "dark",
                        my: [0, 0]
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: page.description }}
                  />
                </Flex>
              </Container>
            </>
          )}
        </i18nContext.Consumer>
      </Box>
      {page.content.map(block => (
        <section key={block.id}>
          {block.model.apiKey === "text_and_image" && (
            <TextAndImage
              title={block.title}
              text={block.text}
              image={block.image}
              video={block.video}
              linkText={block.linkText}
            />
          )}
          {block.model.apiKey === "event_collection" && (
            <EventCollection title={block.title} eventi={block.eventi} />
          )}
          {block.model.apiKey === "space_collection" && (
            <SpaceCollection
              title={block.title}
              spaces={block.spaces}
              link={block.link}
            />
          )}
          {block.model.apiKey === "actor_collection" && (
            <ActorsCollection title={block.title} actors={block.actors} />
          )}
          {block.model.apiKey === "test_us_section" && (
            <TestUsSection
              title={block.title}
              description={block.description}
              linkText={block.linkText}
              list={block.list}
              image={block.image}
            />
          )}
          {block.model.apiKey === "contact_form" && (
            <ContactForm
              kicker={block.kicker}
              title={block.title}
              privacyPolicyDescription={block.privacyPolicyDescription}
              newsletterDescription={block.newsletterDescription}
              description={block.description}
            />
          )}
        </section>
      ))}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      kickerOne
      title
      subtitle
      description
      content {
        ... on DatoCmsTextAndImage {
          id
          title
          text
          linkText
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          video {
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEventCollection {
          id
          title
          eventi {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSpaceCollection {
          id
          title
          spaces {
            title
            gallery {
              url
              gatsbyImageData
            }
            description
            link {
              ... on DatoCmsExternalLink {
                id
                anchor
                url
                model {
                  apiKey
                }
              }
            }
          }
          link {
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTestUsSection {
          title
          description
          linkText
          image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          list {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsActorCollection {
          title
          actors {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          description
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 6
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
        }
      }
      value
    }
    link {
      ... on DatoCmsExternalLink {
        id
        anchor
        url
        model {
          apiKey
        }
      }
    }
    model {
      apiKey
    }
  }
`
