import React, { useState } from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { InboundLink, OutboundLink } from "../link"
// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
SwiperCore.use([Mousewheel, Pagination, A11y])

const SpaceCollection = ({ title, spaces, link }) => {
  return (
    <Box sx={{ position: "relative", py: [5, 6] }}>
      <Container variant="custom" sx={{ py:[0,0]}} >
        <Heading
          variant="h2"
          as="h2"
          sx={{
            mt: [0, 0],
            mb: [3, 4],
            color: "dark",
            textTransform: "lowercase",
          }}
        >
          {title}
        </Heading>
      </Container>
      <Container sx={{ py: [0, 0] }}>
        <Grid
          columns={[1, 1, 3]}
          sx={{
            borderTop: "1px solid",
            borderColor: "dark",
          }}
        >
          {spaces.map((space, index) => (
            <Grid
              columns={[1, 1, 1]}
              sx={{
                gridTemplateRows: [".6fr .4fr"],
                py: [3, 4],
              }}
            >
              <Box>
                <SpaceSwiper index={index} space={space} />
              </Box>
              <Flex
                sx={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Heading
                    as="h3"
                    variant="h3"
                    color="dark"
                    sx={{ mt: [0, 0], lineHeight: "40px" }}
                  >
                    {space.title}
                  </Heading>
                </Box>
                <Box>
                  <Text
                    as="div"
                    variant="p"
                    sx={{
                      p: { fontSize: [3, 4], my: [2, 3] },
                    }}
                    dangerouslySetInnerHTML={{ __html: space.description }}
                  />
                </Box>
                <Box>
                  <OutboundLink
                    target="_blank"
                    href={space.link.url}
                    sx={{
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <Flex sx={{ alignItems: "center", fontSize: [1] }}>
                      <Box as="span" sx={{ mr: [2] }}>
                        ⟶
                      </Box>
                      {space.link.anchor}
                    </Flex>
                  </OutboundLink>
                </Box>
              </Flex>
            </Grid>
          ))}
        </Grid>
        <Flex
          sx={{
            width: "100%",
            justifyContent: "end",
          }}
        >
          <OutboundLink
            target="_blank"
            href={link.url}
            sx={{
              display: "inline-block",
              mt: [4],
              border: "1px solid",
              borderColor: "dark",
              py: [2],
              px: [3],
              position: "relative",
              color: "dark",
              textDecoration: "none",
              borderRadius: "lg",
              textTransform: "lowercase",
              fontSize: [3, 3],
              transition: "all ease .3s",
              transform: ["rotate(-10deg)","rotate(-10deg)","rotate(-0deg)"],
              "&:hover": {
                transform: "rotate(-10deg)",
              },
            }}
          >
            {link.anchor}
          </OutboundLink>
        </Flex>
      </Container>
    </Box>
  )
}

const SpaceSwiper = ({ index, space }) => {
  const [swiperIndex, setSwiperIndex] = useState(1)
  
  
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: "80%",
          transform:"translateY(-80%)",
          right: [2],
          zIndex: 1,
          textAlign:"center",
          minWidth:"60px",
          borderRadius:"lg",
          py:[1,1],
          px:[1,1],
          fontSize:[3],
          backgroundColor: "light",
        }}
      >
        {swiperIndex}/{space.gallery.length}
      </Box>
      <Swiper
        id={"space" + index}
        spaceBetween={32}
        pagination={{ clickable: true }}
        autoHeight={true}
        slidesPerView={1}
        style={{ zIndex: 0 }}
        onActiveIndexChange={(swiper) => {
          console.log(swiper)
          setSwiperIndex(swiper.activeIndex +1)
        }}
      >
        {space.gallery.map((image, index) => (
          <SwiperSlide key={image.id}>
            <Box
              sx={{
                position: "relative",
                ".gatsby-image-wrapper": {
                  width: "100%",
                  height: "100%",
                  maxHeight: ["380px"],
                  img: {
                    height: "auto",
                  },
                },
              }}
            >
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default SpaceCollection
